const BASE_URL_AUTH = `${process.env.VUE_APP_AUTH_SERVICE_URL}/v1/cms`;
const BASE_URL_MASTER = `${process.env.VUE_APP_MASTER_SERVICE_URL}/v1/cms`;

// Auth
export const API_AUTH = {
  LOGIN: `${BASE_URL_AUTH}/authorization/login`,
  LOGOUT: `${BASE_URL_AUTH}/authorization/logout`,
  FORGOT_PASSWORD: `${BASE_URL_AUTH}/authorization/forgot-password`,
  CHANGE_PASSWORD: `${BASE_URL_AUTH}/authorization/change-password`,
  PROFILE: `${BASE_URL_AUTH}/account/profile`,
  ROLE_MENU: `${BASE_URL_AUTH}/account/role-menu`,
  MENU: `${BASE_URL_AUTH}/master/menu`,
  CREATE_MENU: `${BASE_URL_AUTH}/master/menu`,
  UPDATE_MENU: `${BASE_URL_AUTH}/master/menu`,
  DETAIL_MENU: id => `${BASE_URL_AUTH}/master/menu/${id}`,
  PARENT: `${BASE_URL_AUTH}/master/menu/parent`,
  USER: `${BASE_URL_AUTH}/master/user`,
  CREATE_USER: `${BASE_URL_AUTH}/master/user`,
  UPDATE_USER: (id) =>`${BASE_URL_AUTH}/master/user/${id}`,
  DETAIL_USER: id => `${BASE_URL_AUTH}/master/user/${id}`,
  DEPARTMENT: `${BASE_URL_AUTH}/master/user/departments`,
  REGION: `${BASE_URL_AUTH}/master/user/regions`,
  SUB_REGION: `${BASE_URL_AUTH}/master/user/sub-regions`,
  BRANCHES: `${BASE_URL_AUTH}/master/user/branches`,
  ROLES: `${BASE_URL_AUTH}/master/user/roles`,
  ROLE: `${BASE_URL_AUTH}/master/role`,
  CREATE_ROLE: `${BASE_URL_AUTH}/master/role`,
  UPDATE_ROLE: (id)=>`${BASE_URL_AUTH}/master/role/${id}`,
  DETAIL_ROLE: id => `${BASE_URL_AUTH}/master/role/${id}`,
  MODULE: `${BASE_URL_AUTH}/master/module`
};

export const API_MASTER = {
  MENU: `${BASE_URL_MASTER}/master/menu`,
  CREATE_MENU: `${BASE_URL_MASTER}/master/menu`,
  UPDATE_MENU: `${BASE_URL_MASTER}/master/menu`,
  DETAIL_MENU: id => `${BASE_URL_MASTER}/master/menu/${id}`,
  PARENT: `${BASE_URL_MASTER}/master/menu/parent`,
  USER: `${BASE_URL_MASTER}/master/user`,
  CREATE_USER: `${BASE_URL_MASTER}/master/user`,
  UPDATE_USER: (id) =>`${BASE_URL_MASTER}/master/user/${id}`,
  DETAIL_USER: id => `${BASE_URL_MASTER}/master/user/${id}`,
  DEPARTMENT: `${BASE_URL_MASTER}/master/user/departments`,
  REGION: `${BASE_URL_MASTER}/master/user/regions`,
  SUB_REGION: `${BASE_URL_MASTER}/master/user/sub-regions`,
  BRANCHES: `${BASE_URL_MASTER}/master/user/branches`,
  ROLES: `${BASE_URL_MASTER}/master/user/roles`,
  ROLE: `${BASE_URL_MASTER}/master/role`,
  CREATE_ROLE: `${BASE_URL_MASTER}/master/role`,
  UPDATE_ROLE: (id)=>`${BASE_URL_MASTER}/master/role/${id}`,
  DETAIL_ROLE: id => `${BASE_URL_MASTER}/master/role/${id}`,
  MODULE: `${BASE_URL_MASTER}/master/module`,
  MAPPING_APPROVAL: `${BASE_URL_MASTER}/master/approval`,
  CREATE_MAPPING_APPROVAL: `${BASE_URL_MASTER}/master/approval`,
  UPDATE_MAPPING_APPROVAL: `${BASE_URL_MASTER}/master/approval`,
  DETAIL_MAPPING_APPROVAL: id => `${BASE_URL_MASTER}/master/approval/${id}`,
  TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit`,
  CREATE_TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit`,
  UPDATE_TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit`,
  DETAIL_TARGET_VISIT: id => `${BASE_URL_MASTER}/master/target-visit/${id}`,
  UPLOAD_TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit/upload`,
  BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet`,
  CREATE_BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet`,
  UPDATE_BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet`,
  DETAIL_BR_OUTLET: id => `${BASE_URL_MASTER}/master/br-outlet/${id}`,
  UPLOAD_BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet/upload`
};
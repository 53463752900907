import { apiPostNonAuth, apiDeleteAuth, apiPostAuth } from '@/utils/api';
import { API_AUTH } from '@/utils/api-url';
import { setCookie, setCookieProfile, getUserToken, getUserProfile, logoutUser } from '@/utils/auth';

const state = {
  token: getUserToken() || null, // Get token from cookie
  profile: getUserProfile() || null, // Get profile from cookie
  role: getUserProfile()?.role || null, // Get role from profile cookie
  menu: getUserProfile()?.menu || [], // Get menu from profile cookie
  isAuthenticated: !!getUserToken(), // Initialize authentication status based on token existence
  loading: false,
  error: null
};

const mutations = {
  SET_AUTH_DATA(state, payload) {
    state.token = payload.token;
    state.profile = payload.profile;
    state.role = payload.role;
    state.menu = payload.menu;
    state.isAuthenticated = true;

    // Set cookies for auth data
    setCookie(payload.token);
    setCookieProfile(JSON.stringify(payload.profile)); // Store profile as JSON string in cookie
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  RESET_STATE(state) {
    state.token = null;
    state.profile = null;
    state.role = null;
    state.menu = [];
    state.isAuthenticated = false;
    state.loading = false;
    state.error = null;

    // Remove auth data from cookies
    logoutUser();
  }
};

const actions = {
  async login({ commit }, credentials) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await apiPostNonAuth(API_AUTH.LOGIN, credentials);
      if (response.data.status_code === 200) {
        if (response.data.data.profile.verify_password != 0) {
          const { token, profile, role } = response.data.data;
          const menu = role.menu;
          commit('SET_AUTH_DATA', { token, profile, role, menu });
          return response.data;
        } else {
          const { profile } = response.data.data;
          commit('SET_AUTH_DATA', { profile });
          return response.data;
        }
      } else {
        throw new Error(response.data.message || 'Login failed');
      }
    } catch (error) {
      commit('SET_ERROR', 'Login failed. Please check your credentials.');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async logout({ commit }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    
    try {
      // Send a DELETE request to the logout endpoint
      const response = await apiDeleteAuth(API_AUTH.LOGOUT);
      
      if (response.data.status_code === 200) {
        // Clear the auth state after a successful logout
        commit('RESET_STATE');
      } else {
        throw new Error(response.data.message || 'Logout failed');
      }
    } catch (error) {
      commit('SET_ERROR', 'Logout failed. Please try again.');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async forgot({ commit }, email) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await apiPostNonAuth(API_AUTH.FORGOT_PASSWORD, { email });
      if (response.data.status_code === 200) {
        return response.data.message; // Return success message
      } else {
        throw new Error(response.data.message || 'Failed to send reset password link');
      }
    } catch (error) {
      commit('SET_ERROR', 'Failed to send reset password link. Please try again.');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async resetPassword({ commit }, { token, newPassword, confirmPassword }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await apiPostNonAuth(API_AUTH.RESET_PASSWORD, {
        token,
        new_password: newPassword,
        confirm_password: confirmPassword
      });

      if (response.data.status_code === 200) {
        return response.data.message; // Success
      } else {
        commit('RESET_STATE');
        throw new Error(response.data.message || 'Password reset failed');
      }
    } catch (error) {
      commit('RESET_STATE');
      commit('SET_ERROR', 'Password reset failed. Please try again.');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async changePassword({ commit }, { auth, old_password, new_password, verify_new_password }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);

    const { token, profile, role } = auth.data;
    const menu = role.menu;
    commit('SET_AUTH_DATA', { token, profile, role, menu });
    
    try {
      const response = await apiPostAuth(API_AUTH.CHANGE_PASSWORD, {
        old_password: old_password,
        new_password: new_password,
        verify_new_password: verify_new_password
      });

      if (response.data.status_code === 200) {
        return response.data.message; // Success
      } else {
        throw new Error(response.data.message || 'Password reset failed');
      }
    } catch (error) {
      commit('SET_ERROR', 'Password reset failed. Please try again.');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  initialize({ commit }) {
    // Initialize the auth state based on cookies
    const token = getUserToken();
    const profile = getUserProfile();

    if (token) {
      commit('SET_AUTH_DATA', {
        token,
        profile,
        role: profile?.role || null,
        menu: profile?.menu || []
      });
    }
  },
  
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getUserProfile(state) {
    return state.profile;
  },
  getUserRole(state) {
    return state.role;
  },
  getUserMenu(state) {
    return state.menu;
  },
  isLoading(state) {
    return state.loading;
  },
  getError(state) {
    return state.error;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

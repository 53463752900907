<template>
  <aside class="main-sidebar sidebar-light-primary">
    <!-- Brand Logo -->
    <img src="@/assets/logo/logo.png" alt="AdminLTE Logo" class="brand-image" style="opacity: .8">

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li v-for="menu in computedMenus" :key="menu.title" class="nav-item" :class="{ 'menu-open': menu.open }">
            <!-- Conditionally render either a router-link or a normal anchor for parent menus -->
            <template v-if="menu.children">
              <a 
                href="#" 
                class="nav-link" 
                :class="{ active: $route.path === menu.path }"
              >
                <i :class="menu.icon"></i>
                <p>
                  {{ menu.title }}
                  <i v-if="menu.children" class="right fas fa-angle-left"></i>
                </p>
              </a>
              <!-- Child Menus -->
              <ul class="nav nav-treeview">
                <li v-for="child in menu.children" :key="child.path" class="nav-item">
                  <router-link 
                    :to="child.path" 
                    class="nav-link"
                    :class="{ active: $route.path.startsWith(child.path) }"
                  >
                    <p>{{ child.title }}</p>
                  </router-link>
                </li>
              </ul>
            </template>
            <!-- If the menu does not have children, directly link to its path -->
            <template v-else>
              <router-link 
                :to="menu.path" 
                class="nav-link"
                :class="{ active: $route.path === menu.path }"
              >
                <i :class="menu.icon"></i>
                <p>{{ menu.title }}</p>
              </router-link>
            </template>
          </li>
        </ul>
      </nav>
      <div class="user-profile d-flex align-items-center p-2" v-if="profile && profile.username">
        <img src="@/assets/logo/user.png" alt="User Avatar" class="img-circle elevation-2" style="width: 40px; height: 40px;">
        <div class="info ml-2">
          <span class="d-block">{{ profile.username }}</span>
          <small class="d-block">{{ profile.email }}</small>
        </div>
        <a href="/logout" class="ml-auto">
          <i class="fas fa-sign-out-alt"></i>
        </a>
      </div>

      <!-- Show a loading state while profile is undefined -->
      <div class="user-profile d-flex align-items-center p-2" v-else>
        <img src="@/assets/logo/user.png" alt="User Avatar" class="img-circle elevation-2" style="width: 40px; height: 40px;">
        <div class="info ml-2">
          <span class="d-block">Loading...</span>
          <small class="d-block">Loading...</small>
        </div>
      </div>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export const dynamicMenuItems = [
  { 
    title: "Data Master", 
    path: "/data-master", 
    icon: "nav-icon fa fa-database", 
    children: [
      { title: "User", path: "/user", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Role", path: "/role", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Menu", path: "/menu", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Target Visit", path: "/target-visit", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Outlet", path: "/outlet", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
      { title: "Mapping Approval", path: "/mapping-approval", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
    ],
    open: true
  },
  { title: "Program & Promo", path: "/program-promo", icon: "nav-icon fa fa-star", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
  { title: "Event Management", path: "/event-management", icon: "nav-icon fa fa-calendar", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
  { title: "Mission Gamification", path: "/mission-gamification", icon: "nav-icon fa fa-trophy", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
  { title: "Approval", path: "/approval", icon: "nav-icon fa fa-circle", permission: { "create": true, "read": true, "update": true, "delete": true, "approve": true, "upload": true } },
];

export default {
  data() {
    return {
      dynamicMenus: dynamicMenuItems,
    };
  },
  computed: {
    ...mapGetters('account', ['getUserProfile', 'getUserRoleMenu']),
    ...mapState('account', ['profile', 'roleMenu']),
    computedMenus() {
      return this.dynamicMenus; // Dynamically render the menus
    },
  },
  methods: {
    ...mapActions('account', ['fetchUserProfile', 'getRoleMenu']),
    async loadRoleMenu() {
      try {
        await this.getRoleMenu();  // Correctly call the action with parentheses
        await this.fetchUserProfile(); // Correctly call the action with parentheses
        console.log('Profile fetched:', this.profile);
      } catch (error) {
        console.log('Error loading role menu or profile:', error);
      }
    },
  },
  watch: {
    '$route.path'() {
      this.dynamicMenus.forEach(menu => {
        if (menu.children) {
          menu.open = menu.children.some(child => this.$route.path.startsWith(child.path));
        }
      });
    },
  },
  mounted() {
    this.loadRoleMenu(); // Fetch data on mount
  },
};

</script>

<style>
.nav-treeview .nav-item .nav-link {
  padding-left: 47px;
  color: #495057 !important;
}
.nav-sidebar .nav-link.active {
  background-color: #CCE1FA !important;
  color: #234670 !important;
}

.user-profile {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  max-width: 100%; /* Ensure the profile section fits within the sidebar */
  overflow: hidden; /* Prevent the content from overflowing the container */
}

.user-profile img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex-shrink: 0; /* Prevent the avatar from shrinking */
}

.user-profile .info {
  margin-left: 10px;
  overflow: hidden; /* Hide overflowing content */
  white-space: nowrap; /* Prevent the text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis when text overflows */
  max-width: calc(100% - 60px); /* Ensure the info section doesn't exceed the sidebar width, considering the avatar width */
}

.user-profile .info span,
.user-profile .info small {
  display: block;
  font-weight: bold;
  color: #343a40;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis if the text overflows */
}

.user-profile .info small {
  font-weight: normal;
  font-size: 10px;
  color: #6c757d;
}

.user-profile a {
  color: #6c757d;
  margin-left: auto; /* Push the logout icon to the far right */
}

.user-profile a:hover {
  color: #343a40;
}


</style>

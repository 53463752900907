import { createStore } from 'vuex';
import auth from './modules/auth';
import account from './modules/account';
import user from './modules/user';
import menu from './modules/menu';
import role from './modules/role';
import mappingApproval from './modules/mapping-approval';
import outlet from './modules/outlet';
import targetVisit from './modules/target-visit';

export default createStore({
  modules: {
    auth,
    account,
    user,
    menu,
    role,
    mappingApproval,
    outlet,
    targetVisit
  }
});
